// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import pinsApi from '../../api/pins';
import pinTypesApi from '../../api/pin_types';
import useApi from '../../hooks/useApi';
import { uploadAttachment } from '../../hooks/useS3';
import PinDropService from '../../services/PinDropService';
import Button from '../shared/Button';
import Textarea from '../shared/Textarea';
import Loader from '../utility/Loader';
import Modal from '../utility/Modal';
import PinDropOverlay from './PinDropOverlay';
import FieldBoxes from './pin_form_fields/FieldBoxes';
import FieldStatuses from './pin_form_fields/FieldStatuses';
import ImageInput from './pin_form_fields/ImageInput';
import MapBoxMapLayers from '../map_layers/mapbox/MapBoxMapLayers';
import Tags from '../shared/Tags';
import useFeatures from '../../hooks/useFeatures';
import FieldNumeric from './pin_form_fields/FieldNumeric';
import Header from './modal/Header';
import Title from './modal/Title';
import Footer from './modal/Footer';
import ModalButton from './modal/Button';
import PrivateSwitch from './pin_form_fields/PrivateSwitch';
import FieldContainer from './pin_form_fields/FieldContainer';
import mapLayersApi from '../../api/map_layers';
import { mapboxStyleUrls } from '../../data/models';

const svgConfig = {
  circle: {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    anchor: { x: 270, y: 290 },
    color: '#276093',
  },
  triangle: {
    path: 'M490,474.459H0L245.009,15.541L490,474.459z',
    anchor: { x: 260, y: 330 },
    color: '#9FD53D',
  },
  plus: {
    path: 'M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z',
    anchor: { x: 230, y: 250 },
    color: '#276093',
  },
};

const chosenValuesForField = ({ pin, field }) => {
  const {
    id: fieldId,
    component_name,
    field_options: options,
  } = field;

  let chosenFieldOption = null;
  const existingFieldValues =
    pin.field_values_attributes?.filter(
      (fv) => fv.field_id === fieldId
    ) || [];

  let existingFieldValue = false;

  if (existingFieldValues.length) {
    if (component_name === 'FieldNumeric') {
      chosenFieldOption = existingFieldValues
        .map((fv) => {
          fv.color = '#ffffff';
          fv.name = fv.value;
          return fv;
        })
        .filter((fv) => {
          return fv._destroy === undefined;
        });
    } else {
      chosenFieldOption =
        component_name === 'FieldTags'
          ? options.filter(
            (o) =>
              o._destroy === undefined &&
              (existingFieldValues
                .map((efv) => efv.value)
                .indexOf(o.value) > -1 ||
                existingFieldValues
                  .map((efv) => efv.field_option_id)
                  .indexOf(o.id) > -1)
          )
          : options.find(
            (o) =>
              o._destroy === undefined &&
              (o.value === existingFieldValues[0].value ||
                o.id === existingFieldValues[0].field_option_id)
          );
    }
  }

  if (component_name === 'FieldSwitch')
    existingFieldValue =
      existingFieldValues && existingFieldValues.length
        ? existingFieldValues[0].value
        : false;

  return {
    existingFieldValue,
    chosenFieldOption,
  }
}

export default function NewPinModal({
  position: passedPosition,
  mapLayers: passedMapLayers = [],
  zoom,
  onClose,
  projectId,
  existingPin,
}) {

  const { data: pinTypes, request: loadPinTypes } = useApi(
    pinTypesApi.getProjectPinTypes,
    []
  );

  const { data: {
    records: pinMapLayers = []
  }, request: loadPinMapLayers } = useApi(
    mapLayersApi.getPinMapLayers,
    { records: [], pagy: {} }
  );

  const [newPin, setNewPin] = useState({
    notes_attributes: existingPin ? [] : [{ text: '' }],
    pin_map_layers_attributes: existingPin
      ? []
      : passedMapLayers.map((ml) => ({ map_layer_id: ml.id })),
    field_values_attributes: existingPin ? existingPin.field_values : null,
    coordinate_attributes: existingPin
      ? existingPin.coordinate
      : { lat: passedPosition?.latitude, lng: passedPosition?.longitude },
  });
  const [tags, setTags] = useState(existingPin?.tags_with_colors || []);
  const originalPosition = existingPin?.coordinate || passedPosition;
  const [position, setPosition] = useState(originalPosition);
  const positionResetable = () =>
    position?.lat !== originalPosition?.lat &&
    position?.lng !== originalPosition?.lng;
  const [mapState, setMapState] = useState('default');
  const [noteImages, setNoteImages] = useState([]);
  const [chosenPinType, setChosenPinType] = useState(existingPin?.pin_type);
  const [chosenPinStatus, setChosenPinStatus] = useState(
    existingPin?.active_status
  );
  const [icon, setIcon] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const { project_id } = useParams();
  const { features: { rollout: { pin_tags, custom_pin_ids } = {} } = {} } =
    useFeatures();
  const { request: addPin } = useApi(pinsApi.addPin, null);
  const { request: updatePin } = useApi(pinsApi.updatePin, null);
  const mapContainer = useRef(null);
  const mapboxMap = useRef(null);
  const [mapboxCanRenderLayers, setMapboxCanRenderLayers] = useState(false);
  const [mapLayers, setMapLayers] = useState(passedMapLayers);
  const [mapboxMarker, setMapboxMarker] = useState(null);

  useEffect(() => {
    loadPinTypes(project_id);
  }, []);

  useEffect(() => {
    if (!existingPin) return;
    loadPinMapLayers(existingPin?.objectId);
  }, [existingPin])

  useEffect(() => {
    if (pinMapLayers.length === 0) return;
    setMapLayers(pinMapLayers);
  }, [pinMapLayers.map(({ objectId }) => objectId).join(',')])

  useEffect(() => {
    if (!existingPin || pinTypes.length === 0) return;
    setChosenPinType(pinTypes.find((x) => x.id === existingPin?.pin_type.id));
    setChosenPinStatus(existingPin.active_status);
    setNewPin({ ...newPin, private: existingPin.private });
  }, [existingPin, pinTypes]);

  useEffect(() => {
    if (position) return;
    setIcon(null);
  }, [position]);

  useEffect(() => {
    let svg = svgConfig[chosenPinType?.icon?.name] || svgConfig.plus;
    let icon = {
      path: svg.path,
      fillColor:
        existingPin?.color ||
        chosenPinType?.pin_color ||
        chosenPinStatus?.color ||
        svg.color,
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.1,
      anchor: svg.anchor,
    };
    setIcon(icon);
    if (existingPin?.pin_type !== chosenPinType) newPin.field_values_attributes = [];
    setFormComplete(false);
  }, [chosenPinType]);

  useEffect(() => {
    if (mapboxMap.current || !position || !mapContainer.current) return;
    mapboxMap.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapboxStyleUrls['satellite-streets'],
      center: [position.lng, position.lat],
      zoom: zoom ?? 16,
    });

    //Apparently this is needed to make the map *actually* fit its container
    mapboxMap.current.on('render', () => {
      mapboxMap.current.resize();
    });

    mapboxMap.current.on('load', () => {
      setMapboxCanRenderLayers(true);
    });
  }, [mapContainer.current]);

  useEffect(() => {
    if (!mapState) return;
    let listener;
    if (mapState === 'updateLocation') {
      mapboxMap.current.resize();
      listener = (e) => {
        setPosition({
          lat: e.lngLat.lat,
          lng: e.lngLat.lng,
        });
      };
    }
    mapboxMap?.current?.on('click', listener);
    return () => mapboxMap?.current?.off('click', listener);
  }, [mapState]);

  useEffect(() => {
    if (!icon || !mapboxMap.current) return;
    if (mapboxMarker) mapboxMarker.remove();
    const marker = createNewMapboxPinMarker(icon);
    setMapboxMarker(marker);
  }, [icon]);

  useEffect(() => {
    if (!position || !icon || !mapboxMap.current) return;
    if (mapboxMarker) mapboxMarker.remove();
    const marker = createNewMapboxPinMarker(icon);
    setMapboxMarker(marker);
  }, [position]);

  const createNewMapboxPinMarker = (icon) => {
    let DEFAULT_HEIGHT = 525;
    let DEFAULT_WIDTH = 525;
    let scale = 0.05;

    const _div = window.document.createElement('div');
    const _svg = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    const _path = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );

    const svgAttributes = {
      display: 'block',
      height: `${DEFAULT_WIDTH * scale}px`,
      width: `${DEFAULT_WIDTH * scale}px`,
      viewBox: `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`,
      fill: icon.fillColor,
      stroke: '#fff',
      'stroke-width': '30px',
    };

    for (const name of Object.keys(svgAttributes)) {
      _svg.setAttributeNS(null, name, svgAttributes[name]);
    }
    _div.appendChild(_svg);

    const pathAttributes = {
      ...icon,
      d: icon.path,
      scale: scale,
    };

    for (const name of Object.keys(pathAttributes)) {
      _path.setAttributeNS(null, name, pathAttributes[name]);
    }
    _svg.appendChild(_path);

    const marker = new mapboxgl.Marker({
      element: _div,
    })
      .setLngLat([position.lng, position.lat])
      .addTo(mapboxMap.current);

    return marker;
  };

  const updateChosenPinTypeFromId = (pin_type_id) => {
    if (parseInt(pin_type_id) === -1) {
      setChosenPinType(null);
      return;
    }
    const newPinType = pinTypes.find((t) => t.id === parseInt(pin_type_id));
    newPinType && setChosenPinType({ ...newPinType });
  };

  const updateFormState = (options, multiSelect, fieldId) => {
    addOption(options, fieldId, multiSelect);
  };

  const removeFieldValue = (field_value, { auto_fills } = {}) => {
    newPin.field_values_attributes = newPin.field_values_attributes
      .map((fv) => {
        if (
          fv.objectId &&
          field_value.objectId == fv.objectId &&
          fv.objectId.includes('Input')
        )
          return null;
        if (
          fv.objectId &&
          field_value.objectId == fv.objectId &&
          !fv.objectId.includes('Input')
        )
          return { ...fv, _destroy: true };
        if (fv.field_option_id && fv.field_option_id === field_value.field_option_id && fv.id)
          return { ...fv, _destroy: true };
        if (
          fv.field_option_id !== field_value.field_option_id ||
          (fv.objectId && field_value.objectId !== fv.objectId)
        )
          return fv;
        return null;
      })
      .filter((n) => n);

    //if (auto_fills) removeFieldCache(field_value);

    setNewPin({ ...newPin });
  };


  const updateFieldValue = (
    field_value,
    { allows_multiple = false, auto_fills = false } = {}
  ) => {
    if (!newPin.field_values_attributes) newPin.field_values_attributes = [];

    const existingFieldValueIndex = newPin.field_values_attributes.findIndex(
      (fv) => fv.field_id === field_value.field_id
    );

    if (!allows_multiple && existingFieldValueIndex > -1) {
      newPin.field_values_attributes[existingFieldValueIndex] = newPin
        .field_values_attributes[existingFieldValueIndex].id
        ? {
          id: newPin.field_values_attributes[existingFieldValueIndex].id,
          ...field_value,
        }
        : field_value;
    } else newPin.field_values_attributes.push(field_value);
    //if (auto_fills) updateFieldCache(field_value); //TODO add caching for auto fills

    setNewPin({ ...newPin });
  };

  const handleToggleChange = (field) => {
    const index = newPin.field_values_attributes.findIndex(
      (x) => x.field_id === field.id
    );
    let copy = { ...newPin };
    const currToggleValue = copy.field_values_attributes[index]?.value;

    if (index !== -1) {
      copy.field_values_attributes[index].value = !currToggleValue;
    } else {
      copy.field_values_attributes.push({
        field_id: field.id,
        value: true,
      });
    }

    setNewPin({ ...copy });
  };

  const addOption = (field, id, allowsMultiple, isString, isFieldPicker) => {
    field = isString ? JSON.parse(field) : field;
    if (!newPin.field_values_attributes) {
      newPin.field_values_attributes = [];
    }

    if (isFieldPicker) {
      const existingFieldPickerValues =
        existingPin?.field_values?.filter((x) => x.field_id === id) ?? [];
      const allValues = [
        ...existingFieldPickerValues,
        ...newPin.field_values_attributes,
      ];
      newPin.field_values_attributes = allValues?.map((t) =>
        t.field_id === id ? { ...t, _destroy: true } : t
      );
    } else {
      newPin.field_values_attributes = newPin.field_values_attributes?.filter(
        (t) => t.field_id !== id && t.field_option_id !== id
      );
    }

    field?.map((f) => {
      newPin.field_values_attributes.push({
        id: findFieldId(f.id),
        field_id: id,
        field_option_id: f.field_option_id ?? f.id,
        value: f.value,
        _destroy: f._destroy,
      });
      return null;
    });

    setNewPin({ ...newPin });
  };

  const checkForFormComplete = () => {
    if (existingPin) return setFormComplete(true);
    const pinDropService = new PinDropService({
      data: newPin,
      type: chosenPinType,
      status: chosenPinStatus,
      imageUris: noteImages,
    });
    setFormComplete(pinDropService.isComplete());
  };

  const updateNewPin = (key, value) => {
    if (key === 'pin_type_id') {
      return updateChosenPinTypeFromId(value);
    }
    newPin[key] = value;
    setNewPin({ ...newPin });
  };

  const formatFormFieldLabel = (label) => {
    return label.replace(/_/, ' ');
  };

  const getFieldPickerValue = (currExistingPin, field) => {
    const fieldValue = currExistingPin?.field_values?.find(
      (x) => x.field_id === field.id
    );
    const x = field?.field_options?.find(
      (y) => y.objectId === fieldValue?.field_option?.objectId
    );
    return JSON.stringify([x]);
  };

  const getDefaultFieldSwitchValue = (currExistingPin, field) => {
    const valueIndex = newPin?.field_values_attributes?.findIndex(
      (x) => x.field_id === field.id
    );

    if (valueIndex !== -1 && valueIndex !== undefined) {
      const rsult = newPin?.field_values_attributes[valueIndex]?.value;
      return rsult;
    } else {
      return (
        currExistingPin?.field_values?.find((x) => x.field_id === field.id)
          ?.value === 't'
      );
    }
  };

  const generateFormField = (fieldName, field) => {
    if (!field) {
      return;
    }

    const { chosenFieldOption } = chosenValuesForField({ pin: newPin, field });
    const fieldValuesArchivedFieldOptions = existingPin?.field_values?.filter?.(({ field_option, field_id }) => field_id === field.id && !!field_option?.archived_at).map?.(({ field_option }) => field_option) || [];

    switch (field.component_name) {
      case 'FieldNumeric':
        return <FieldNumeric
          title={field.name == 'trunk' ? 'Trunk Sizes(s)' : field.name}
          allowDuplicates={field.allows_duplicates}
          fieldId={field.id}
          dataTestId={`fieldNumeric${field.name}`}
          chosen={chosenFieldOption || []}
          onChosen={updateFieldValue}
          field_options={field.field_options}
          onRemove={removeFieldValue}
          value={
            existingPin
              ? existingPin?.field_values
                .filter((x) => x.field_id === field.id)
                .map((z) => z)
              : null
          }
        />;
      case 'FieldTags':
        return (<FieldBoxes
          options={[...fieldValuesArchivedFieldOptions, ...field.field_options]}
          label={field.name}
          multiSelect={true}
          componentId={field.id}
          updateFormState={updateFormState}
          value={
            existingPin
              ? existingPin?.field_values
                .filter((x) => x.field_id === field.id)
                .map((z) => {
                  return z.field_option;
                })
              : null
          }
        />);
      case 'FieldBoxes':
        return (
          <FieldBoxes
            options={[...fieldValuesArchivedFieldOptions, ...field.field_options]}
            label={formatFormFieldLabel(field.name)}
            multiSelect={false}
            componentId={field.id}
            updateFormState={updateFormState}
            value={
              existingPin
                ? existingPin?.field_values
                  .filter((x) => x.field_id === field.id)
                  .map((z) => {
                    return z.field_option;
                  })
                : null
            }
          />
        );
      case 'FieldPicker':
        return (
          <div className="mb-8">
            <p className="text-secondary font-bold text-lg mb-3 capitalize">
              {formatFormFieldLabel(field.name ?? fieldName)}
            </p>
            <select
              className="bg-gray-50 hover:opacity-60 cursor-pointer text-gray-900 w-full ring-0 focus:outline-none rounded-0 border-t-0 border-l-0 border-r-0 text-xl font-medium border-b-2 border-secondary"
              data-testid={`fieldSelect-${field.name}`}
              onChange={(e) => {
                addOption(e.target.value, field.id, false, true, true);
              }}
              defaultValue={
                existingPin ? getFieldPickerValue(existingPin, field) : null
              }
            >
              <option
                value={null}
                className="capitalize"
              >{`Select ${field.name}`}</option>
              {field.field_options.map((option) => (
                <option key={option.id} value={JSON.stringify([option])}
                  data-testid={`fieldSelect-${option?.name
                    .toLowerCase()
                    .replace(' ', '-')}`}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        );

      case 'FieldSwitch':
        return (
          <div className="flex items-center py-2 mb-6">
            <p className={`mr-2 text-secondary font-bold text-lg capitalize`}>
              {formatFormFieldLabel(field.name ?? fieldName)}
            </p>
            <div
              className="form-switch secondary focus:outline-none"
              onClick={(e) => handleToggleChange(field)}
              data-testId={`fieldSwitch${field.name}`}
            >
              <input
                type="checkbox"
                id="switch"
                className="sr-only"
                checked={getDefaultFieldSwitchValue(existingPin, field)}
              />
              <label className="bg-gray-400" htmlFor="switch">
                <span className="bg-white shadow-sm" aria-hidden="true"></span>
                <span className="sr-only">Switch label</span>
              </label>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const onModalClosed = (loadPins) => {
    setNewPin({});
    setChosenPinType(null);
    setFormComplete(false);
    onClose(loadPins);
  };

  const finalMapLayers = () => {
    if (!existingPin) return mapLayers.map((ml) => ({ map_layer_id: ml.id }))
    //TODO: include pin_map_layer_id on the map_layers for removal
    const layersToRemove = existingPin.pin_map_layers.map((pml) => {
      if (!mapLayers.find(({ id }) => id === pml.map_layer_id)) return {
        _destroy: true,
        id: pml.id,
      }
      else return null
    }).filter(Boolean);

    return mapLayers.map((ml) => {
      const existingMapLayer = existingPin.pin_map_layers.find(({ map_layer_id }) => ml.id === map_layer_id);
      if (existingMapLayer) return null;
      else {
        return { map_layer_id: ml.id }
      }
    }).filter(Boolean).concat(layersToRemove)
  };

  const submitNewPin = async () => {
    try {
      setLoading(true);
      newPin.coordinate_attributes = position;
      newPin.status = chosenPinStatus?.value;
      newPin.pin_type_id = chosenPinType.id;
      newPin.tag_list = tags.map(({ name }) => name).join(', ');
      newPin.pin_map_layers_attributes = finalMapLayers();
      if (newPin?.notes_attributes?.length > 0) {
        newPin.notes_attributes[0].assets_attributes = [];
        for (let i = 0; i < noteImages.length; i++) {
          const signature = await uploadAttachment(noteImages[i]);
          newPin.notes_attributes[0].assets_attributes.push({
            file: signature,
            name: noteImages[i].name,
          });
        }
      }
      if (existingPin) {
        await updatePin(existingPin.objectId, { ...newPin });
      } else {
        await addPin(projectId, { ...newPin });
      }
      onModalClosed(true);
    } catch (error) {
      // TODO: How do we want to handle errors?
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const findFieldId = (fieldOptionId) => {
    if (!fieldOptionId) return;
    return existingPin?.field_values?.find(
      (x) => x?.field_option_id === fieldOptionId
    )?.id;
  };

  const onImageFilesAdded = (files) => {
    setNoteImages(files);
  };

  const disableImages =
    !newPin?.notes_attributes || newPin?.notes_attributes?.length === 0;

  useEffect(() => {
    checkForFormComplete();
  }, [newPin, noteImages, chosenPinStatus]);

  useEffect(() => {
    if (!mapboxMap.current) return;
    mapboxMap.current.getCanvas().style.cursor =
      mapState == 'updateLocation' ? 'crosshair' : 'grab';
  }, [mapState]);

  useLayoutEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Modal
      isOpen={open}
      maxWidth={'max-w-md'}
      maxHeight={'max-h-[80vh]'}
      modalScrolls={false}
      dialogScrolls={true}
      aligned={'items-center'}
      onClose={() => onClose && onModalClosed()}
    >
      {position && (
        <div className="bg-gray-50 pb-36 max-h-[80vh] overflow-x-visible overflow-y-scroll">
          <Header title={existingPin ? 'Update pin' : 'Drop pin'} onClose={onModalClosed} />
          <div
            className={`w-full ${mapState === 'updateLocation' ? 'h-96' : 'h-56'
              } relative`}
          >
            <PinDropOverlay on={mapState === 'updateLocation'} type="update" />
            <div ref={mapContainer} className="w-full h-full">
              {mapboxCanRenderLayers && (
                <MapBoxMapLayers
                  map={mapboxMap.current}
                  defaultLayers={mapLayers}
                  onLayersChosen={setMapLayers}
                  maxHeight={'max-h-28'}
                  canAddLayers={false}
                  canEditLayers={false}
                />
              )}
            </div>
          </div>
          <div className="w-full py-5 px-6">
            <div className="mb-8">
              <div className="flex justify-between items-center">
                <Title>
                  Location
                </Title>
                {positionResetable() && (
                  <Button
                    color="light"
                    text="Reset"
                    size={'xxs'}
                    onClick={() => setPosition(originalPosition)}
                    icon={<FiRefreshCcw className="ml-2" />}
                  />
                )}
              </div>
              {mapState === 'updateLocation' && (
                <Button
                  text={'Set Location'}
                  color={'light'}
                  onClick={() => setMapState('default')}
                  className="w-full mb-2"
                />
              )}
              <Button
                text={
                  mapState === 'updateLocation' ? 'Cancel' : 'Update Location'
                }
                color={mapState === 'updateLocation' ? 'secondary' : 'light'}
                onClick={() => {
                  setMapState(
                    mapState === 'updateLocation' ? 'default' : 'updateLocation'
                  );
                  setPosition(originalPosition);
                }}
                className="w-full"
              />
            </div>
            {custom_pin_ids && (
              <div className="mb-3">
                <Title>
                  Identifier
                </Title>
                <div className="mb-4 border border-gray-200 shadow bg-white">
                  <input
                    placeholder="Pin ID"
                    type="text"
                    data-testid="customPinId"
                    defaultValue={existingPin?.identifier}
                    className="focus:ring-0 px-2 rounded-md placeholder-gray-500 relative text-xs border-0 w-full border-0 outline-none focus:outline-none bg-white"
                    onChange={({ target: { value: identifier } }) => {
                      setNewPin({ ...newPin, custom_identifier: true, identifier });
                    }}
                  />
                </div>
              </div>
            )}
            {pin_tags && (
              <>
                <Title>
                  Tags
                </Title>
                <Tags
                  tags={tags}
                  onAdd={(newTag) => setTags((oldTags) => [...oldTags.filter(({ name }) => name !== newTag.name), newTag])}
                  onRemove={(removedTag) =>
                    setTags((oldTags) => [
                      ...oldTags.filter(({ name }) => name !== removedTag.name),
                    ])
                  }
                  on={'pin'}
                  keyPrefix={'newPin'}
                  containerClassName="normal-case font-normal font-inter items-center flex mt-2 mb-4 flex-wrap"
                  tagClassName={'mr-1 mb-1'}
                  maxTags={0}
                  canAdd={true}
                />
              </>
            )}
            {!existingPin && <>
              <Title>Notes</Title>
              <Textarea
                data-testid="inputPinNote"
                placeholder="Type a note here..."
                type="text"
                className="focus:ring-0 px-2 placeholder-gray-500 relative text-xs border-0 w-full border-0 outline-none focus:outline-none bg-white"
                onChange={(e) => {
                  const notes_attributes = e.target.value
                    ? [{ text: e.target.value }]
                    : [];
                  setNewPin({ ...newPin, notes_attributes });
                }}
              />

              <div className="mb-8">
                <Title>
                  Photos
                </Title>
                <div className="mb-4">
                  <ImageInput
                    onImagesAdded={onImageFilesAdded}
                    disabled={disableImages}
                  />
                </div>
              </div>
            </>}
            <FieldContainer className="flex-column items-center mb-8">
              <Title>
                Private
              </Title>
              <PrivateSwitch onToggle={() => setNewPin({ ...newPin, private: !newPin.private })} isPrivate={newPin.private} />
            </FieldContainer>
            <div className="mb-8">
              <Title>Pin Type</Title>
              <select
                onChange={(e) => updateNewPin('pin_type_id', e.target.value)}
                data-testid="pin-select"
                className="bg-gray-50 hover:opacity-60 cursor-pointer text-gray-900 w-full ring-0 focus:outline-none rounded-0 border-t-0 border-l-0 border-r-0 text-xl font-medium border-b-2 border-secondary"
                defaultValue={existingPin?.pin_type.id}
              >
                <option value={-1}>Select Pin Type</option>
                {pinTypes.map((pinType) => (
                  <option
                    key={pinType.id}
                    value={pinType.id}
                    selected={chosenPinType?.id === pinType.id}
                  >
                    {pinType.name}
                  </option>
                ))}
              </select>
            </div>
            {!!chosenPinType?.statuses?.length > 0 && (
              <div className="mb-8">
                <Title>Status</Title>
                <FieldStatuses
                  options={chosenPinType.statuses}
                  onChosen={(newStatus) => {
                    setChosenPinStatus(newStatus);
                  }}
                  onRemoved={() => setChosenPinStatus(null)}
                  chosen={chosenPinStatus}
                />
              </div>
            )}
            {chosenPinType
              ? chosenPinType?.fieldsets
                ?.filter(({ name }) => name !== 'Status')
                .map((x) =>
                  x.fields.map((y) =>
                    generateFormField(x.name, y, chosenPinType.statuses)
                  )
                )
              : null}
          </div>
        </div>
      )}
      <Footer>
        <ModalButton
          style="save"
          type="button"
          data-testid="savePin"
          disabled={!formComplete || loading}
          onClick={() => submitNewPin()}
        >
          Save Pin
          {loading && <Loader />}
        </ModalButton>
        <ModalButton
          style="close"
          type="button"
          disabled={loading}
          onClick={() => onModalClosed()}
        >
          Cancel
        </ModalButton>
      </Footer>
    </Modal>
  );
}
